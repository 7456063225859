{{if type == 'wrong'}}
<span class="m-login-phTip m-login-codeTip z-wrong-tip">!</span>
{{else if type == 'right'}}
<span class="m-login-phTip z-right-tip"><i class="icon iconfont icon-shuruzhengque"></i></span>
{{/if}}
{{if tip }}
<div class="m-login-phHint">
    <div class="m-login-phHint-rel">
        <!--<i></i>-->

        <div>
            {{tip}}
            {{ if tip == '该手机号已经注册过'}}
            <a href="/{{url}}" class="tip-login-btn">去登录</a>
            {{/if}}
        </div>


    </div>
</div>
{{/if}}