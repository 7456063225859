const __soket = {
    timeout: 5000,
    lockReconnect: false,
    initWebsoket(pamars) {
        let timeoutObj= null,
            serverTimeoutObj = null,
            websoket,
            hostArr = location.host.split('.'),
            hostName = hostArr[hostArr.length-1];

        if (hostName == 'vip') {
            websoket = new WebSocket('ws://10.10.10.24:7004/');
        } else {
            let url = location.protocol == 'https:'?(location.host == 'sso.scimall.org.cn' ? 'wss://im.scimall.org.cn' : 'wss://im.kczg.org.cn'):'ws://im.scimall.net.cn:7001';

            websoket = new WebSocket( url );
        }

        websoket.onopen = () => {
            websoket.send(pamars.msg);
            // 心跳检测重置
            websoket.heartCheck();
        };

        websoket.onmessage = (res) => {
            let dataRes = JSON.parse(res.data);

            // 二维码失效
            if (dataRes.cmd == 'QrcodeNotice' && dataRes.code == '100100011') {
                $('._fCodeFail').show();
            }
            // 扫码成功登录
            if (dataRes.cmd == 'QrcodeNotice' && dataRes.code == 0 && dataRes.data.isLogin == 1) {
                pamars.onmessagefun && pamars.onmessagefun();
            }
            // 获取消息通知
            if (dataRes.cmd == 'noreadNum') {
                let num = dataRes.data.noticeCount + dataRes.data.backlogCount;

                // 评论页面待处理事项
                if ($('._labelItem').length>0) {
                    $('._labelItem').attr('data-num', dataRes.data.backlogCount);
                }

                num == 0?$('._topicNum').hide():$('._topicNum').html(num).show();
            }

            websoket.heartCheck();
        };

        websoket.onclose = () => {
            websoket.close();
        };

        websoket.onerror = () => {
            websoket.reconnect();
        };

        websoket.reconnect = () => {
            let _ = this;

            if (_.lockReconnect) {
                return false;
            }
            _.lockReconnect = true;
            // 没连接上会一直重连，设置延迟避免请求过多
            setTimeout(function () {
                _.initWebsoket();
                _.lockReconnect = false;
            }, 2000);
        };

        websoket.heartCheck = () => {
            let _ = this;

            clearTimeout(timeoutObj);
            clearTimeout(serverTimeoutObj);
            timeoutObj = setTimeout( () => {
                // 这里发送一个心跳，后端收到后，返回一个心跳消息，
                // onmessage拿到返回的心跳就说明连接正常
                websoket.send(`{"cmd":"heartbeat","p":{"uid":0,"status":1}}`);
                serverTimeoutObj = setTimeout( () => {
                    // 如果超过一定时间还没重置，说明后端主动断开了
                    // 如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
                    websoket.close();
                }, _.timeout);
            }, _.timeout);
        };
    }
};


/**
 * parmas 参数格式
 * @url  连接地址
 * @openMsg   连接成功发送的信息内容
 * @message  通信中拿到信息以后的回调
 * @close  关闭以后的回调
 */

export function websoket(pamars) {
    __soket.initWebsoket(pamars);
}
